const leadData = [
  {
    Company: "Freshine Hygiene Products",
    Email: "freshinehygiene@gmail.com",
    Description:
      "I want to buy Tally ERP 9 (Silver) Single User.\r\rKindly send me price and other details.\rRest of the details i will discuss with the supplier. Quantity:   1   Piece Need Demo:   No  Number of User:   Single User  Timeline:   Instant Requirement  Your Budget:   10k - 20k  Why do you need this:   For Personal/Business Use ",
    Full_Name: "Gaurav Kumar",
    Mobile: "+91-9811725735",
    Phone: null,
    City: "Noida",
    Country: "IN",
    State: "Uttar Pradesh",
    First_Name: "Gaurav",
    Last_Name: "Kumar",
    Secondary_Email: null,
    Street:
      "Block UK- II, Plot No. 80, Ecotech-3, Noida, Uttar Pradesh, 201308",
    Product_Interested: "Tally ERP 9 (Silver) Single User",
  },
  {
    Company: "IT Pariwar",
    Email: "itpariwar@gmail.com",
    Description:
      "I am interested in buying Microsoft Windows Server 2008 R2 Standard License Product Key. Kindly send me price and other details. Quantity:   1   Piece Need Demo:   No  Timeline:   After a Week  Why do you need this:   For Reselling ",
    Full_Name: "Manoj Kumar",
    Mobile: "+91-9439065616",
    Phone: null,
    City: "Nayagarh",
    Country: "IN",
    State: "Odisha",
    First_Name: "Manoj",
    Last_Name: "Kumar",
    Secondary_Email: null,
    Street: "At/po- Panchirida Manpur, Ps-sarankul, Nayagarh, Odisha, 752080",
    Product_Interested:
      "Microsoft Windows Server 2008 R2 Standard License Product Key",
  },
  {
    Company: null,
    Email: null,
    Description: null,
    Full_Name: "Vishal",
    Mobile: "+918885565552",
    Phone: null,
    City: "Hyderabad",
    Country: "IN",
    State: "Telangana",
    First_Name: "Vishal",
    Last_Name: "Vishal",
    Secondary_Email: null,
    Street: "",
    Product_Interested: null,
  },
  {
    Company: null,
    Email: null,
    Description: null,
    Full_Name: null,
    Mobile: "+918447644135",
    Phone: null,
    City: null,
    Country: "IN",
    State: null,
    First_Name: "",
    Last_Name: "",
    Secondary_Email: null,
    Street: "",
    Product_Interested: null,
  },
  {
    Company: null,
    Email: "neeleshkumar2573@gmail.com",
    Description:
      "I want to buy ERP Software Packages. Kindly send me price and other details.",
    Full_Name: "NEELESH",
    Mobile: "+91-7224839165",
    Phone: null,
    City: "Bhiwadi",
    Country: "IN",
    State: "Rajasthan",
    First_Name: "NEELESH",
    Last_Name: "NEELESH",
    Secondary_Email: null,
    Street: "Bhiwadi, Rajasthan",
    Product_Interested: "ERP Software Packages",
  },
  {
    Company: null,
    Email: "info@quickbiz.co.tz",
    Description:
      "I am looking for service provider for IT Consultancy.\r\rKindly send me price and other details.<br>Type Of Services : Software Development",
    Full_Name: "Gaudence",
    Mobile: "+255-785670324",
    Phone: null,
    City: null,
    Country: "TZ",
    State: null,
    First_Name: "Gaudence",
    Last_Name: "Gaudence",
    Secondary_Email: null,
    Street: "",
    Product_Interested: "IT Consultancy",
  },
  {
    Company: null,
    Email: "firasathdxb@gmail.com",
    Description:
      "I am interested in buying Billing Software for Garment. Kindly send me price and other details.<br>Quantity : 120 Piece<br>Timeline : After a Week<br>Need Demo : Yes<br>Why do you need this : For Personal/Business Use<br>Your Budget : < 10k",
    Full_Name: "Firasath",
    Mobile: "+971-556369053",
    Phone: null,
    City: null,
    Country: "AE",
    State: null,
    First_Name: "Firasath",
    Last_Name: "Firasath",
    Secondary_Email: null,
    Street: "",
    Product_Interested: "Billing Software for Garment",
  },
  {
    Company: null,
    Email: "yngtsho86@gmail.com",
    Description:
      "I am interested in buying ERP Software. Kindly send me price and other details.",
    Full_Name: "Yangchen",
    Mobile: "+975-",
    Phone: null,
    City: null,
    Country: "BT",
    State: null,
    First_Name: "Yangchen",
    Last_Name: "Yangchen",
    Secondary_Email: null,
    Street: "",
    Product_Interested: "ERP Software",
  },
  {
    Company: "Aman Books",
    Email: "amansty@gmail.com",
    Description:
      "I want to buy Accounting Software.Kindly send me price and other details.<br>Modules/Features : Billing & Invoicing<br>Need Demo : Yes<br>Why do you need this : For Personal/Business Use",
    Full_Name: "Mohamed",
    Mobile: "+91-9895226181",
    Phone: null,
    City: "Tirur",
    Country: "IN",
    State: "Kerala",
    First_Name: "Mohamed",
    Last_Name: "Mohamed",
    Secondary_Email: null,
    Street: "Tirur, Kerala",
    Product_Interested: "Accounting Software",
  },
  {
    Company: "KB Traders",
    Email: "kbtraders14918@gmail.com",
    Description:
      "We need a good billing and inventory management software.<br>Quantity : 1 Pack<br>Modules/Features : Inventory management, Billing & Invoicing<br>Need Demo : Yes<br>Timeline : After a Week<br>Why do you need this : For Personal/Business Use",
    Full_Name: "Nipun Dudeja",
    Mobile: "+91-9041998363",
    Phone: null,
    City: "Zirakpur",
    Country: "IN",
    State: "Punjab",
    First_Name: "Nipun",
    Last_Name: "Dudeja",
    Secondary_Email: null,
    Street:
      "Shop No 16, Lower Ground Bindal Times Square Kishanpura, Zirakpur, Punjab,         140603",
    Product_Interested: "Accounting Software",
  },
  {
    Company: "HSIL Limited",
    Email: "rakeshgupta@hindware.in",
    Description:
      "Please offer the best rate. ERP9 Tally - single user<br>Quantity : 1 Pack<br>Need Demo : No<br>Timeline : Instant Requirement<br>Probable Requirement Type : Business Use",
    Full_Name: "Rakesh Gupta",
    Mobile: "+91-8295907268",
    Phone: "+(91)-(1276)-230485",
    City: "Bahadurgarh",
    Country: "IN",
    State: "Haryana",
    First_Name: "Rakesh",
    Last_Name: "Gupta",
    Secondary_Email: null,
    Street: "NH - 10, Delhi Rohtak Road, Bahadurgarh, Haryana,         124507",
    Product_Interested: "Tally ERP 9 Silver Single User",
  },
  {
    Company: "Prestige Systems",
    Email: "jithendracnair@gmail.com",
    Description:
      "I am interested in Zoho CRM Software, PAN India<br>Other Products Viewed by Buyer : CRM Software, Customer Management Software",
    Full_Name: "JITHENDRA CHIRAKKARA",
    Mobile: "+91-8050804343",
    Phone: "+(91)-(80)-29725020",
    City: "Bengaluru",
    Country: "IN",
    State: "Karnataka",
    First_Name: "JITHENDRA",
    Last_Name: "CHIRAKKARA",
    Secondary_Email: "jithendracnair@prestigesystems.in",
    Street:
      "No. 12, Pristige Building, 3rd Main, Bairaveshwaranagar, Shettihalli Village, Bengaluru, Karnataka,         560015",
    Product_Interested: "Crm Software Sales and Service Electronic",
  },
  {
    Company: null,
    Email: "ayesha2412@gmail.com",
    Description:
      "I am looking for Licensing Services.Kindly send me price and other details. Other Products Viewed by Buyer:   PSARA LICENSE  UTTAR PRADESH ",
    Full_Name: "Ayesha Mahajan",
    Mobile: "+91-9760415427",
    Phone: null,
    City: "Agra",
    Country: "IN",
    State: "Uttar Pradesh",
    First_Name: "Ayesha",
    Last_Name: "Mahajan",
    Secondary_Email: null,
    Street: "Agra, Uttar Pradesh",
    Product_Interested: "Licensing Services",
  },
  {
    Company: null,
    Email: null,
    Description:
      "I want to buy Bulk Email Software.Kindly send me price and other details. Timeline:   Instant Requirement  Why do you need this:   For Personal/Business Use ",
    Full_Name: "VIVEK",
    Mobile: "+91-9033076085",
    Phone: null,
    City: "Surat",
    Country: "IN",
    State: "Gujarat",
    First_Name: "VIVEK",
    Last_Name: "VIVEK",
    Secondary_Email: null,
    Street: "Surat, Gujarat",
    Product_Interested: "Bulk Email Software",
  },
  {
    Company: "Maheswari Computers Private Limited",
    Email: "prateekpardesi786@gmail.com",
    Description:
      "I am interested in Tejas Element Management System Timeline:   Instant Requirement ",
    Full_Name: "Prateek",
    Mobile: "+91-9827784796",
    Phone: null,
    City: "Indore",
    Country: "IN",
    State: "Madhya Pradesh",
    First_Name: "Prateek",
    Last_Name: "Prateek",
    Secondary_Email: null,
    Street:
      "203, Apollo Trade Centre Rajgarh Kothi, AB Road, Geeta Bhawan Square, Indore, Madhya Pradesh, 452002",
    Product_Interested: "Tejas Element Management System",
  },
  {
    Company: "Aura Investments Limited",
    Email: "quinn@aurainvestments.net",
    Description:
      "Good dayWe are looking for various software services .please contact me so i can send the specifications i requirekind regards",
    Full_Name: "Quinn",
    Mobile: "+260-977612016",
    Phone: null,
    City: null,
    Country: "ZM",
    State: null,
    First_Name: "Quinn",
    Last_Name: "Quinn",
    Secondary_Email: "quinnkwalombota@gmail.com",
    Street: "",
    Product_Interested: "Software Developer Services",
  },
];

export default leadData;
