import React from "react";
import NavHeader from "../navheader/NavHeader";

function Header(props) {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        width: "100%",
        borderBottom: "2px solid #7571f9",
        zIndex: "10000"
      }}>
      
      <div className="header" style={{ borderBottom: "1 px solid #555", marginLeft:'0'}}>
      <NavHeader />
        <div className="header-content clearfix">
          <div className="nav-control"></div>
          <div className="header-right">
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
