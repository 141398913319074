import React from "react";
import logo from "../../logo.jpg";

function NavHeader(props) {
  return (
    <div className="nav-header">
      <div className="">
        <a href="/">
          <span className="">
            <img src={logo}  alt="" width="100%" height="80"/>
          </span>
        </a>
      </div>
    </div>
  );
}

export default NavHeader;