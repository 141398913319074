import React from "react";

import cover from "../../cover.png";
import { compareHash } from "../../common/HashUtility";
import { useHistory } from "react-router-dom";
import Constants from "../../common/Contants";
import "./login.scss";
import axios from "axios";
import Loader from "../loader/Loader";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function Login(props) {
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const emailId = event.target.email.value;
    const password = event.target.password.value;
    if (
      emailId &&
      emailId.trim() !== "" &&
      password &&
      password.trim() !== ""
    ) {
      setLoading(true);
      axios
        .get(`${Constants.URL.fetchUserURL}/${emailId}`)
        .then(function (response) {
          setLoading(false);
          if (
            response.data.hashcode &&
            compareHash(password, response.data.hashcode)
          ) {
            const {
              indiaMartMobile,
              indiaMartKey,
              refreshToken,
            } = response.data;
            if (!refreshToken) {
              props.history.push({ pathname: "/zohoCRM", state: response.data });
            } else if (!indiaMartMobile || !indiaMartKey) {
              props.history.push({ pathname: "/indiaMart", state: response.data });
            } else {
              props.history.push({ pathname: "/dashboard", state: response.data });
            }
          }else{
            alert("Invaid Credentials");
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(error.response.data.error);
          setLoading(false);
        });
    }
  };

  const handleSignInBtnClick = () => {
    props.history.push("/register");
  };

  return loading ? (
    <Loader />
  ) : (
    <div style={{ backgroundImage: `url(${cover})`, height: "100%" }}>
      <Header/>
      <div className="login-form-bg h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-xl-5">
              <div className="form-input-content">
                <div className="card login-form mb-0">
                  <div className="card-body pt-5">
                    <h3 className="card-title mt-3 text-center"> Log In </h3>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-envelope icon-align"> </i>
                          </span>
                        </div>
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Email address"
                          type="email"
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-lock icon-align"> </i>
                          </span>
                        </div>
                        <input
                          name="password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn login-form__btn submit w-100">
                          Log In
                        </button>
                      </div>
                      <p className="text-center">
                        Have an account ?
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={handleSignInBtnClick}>
                          Register
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Login;
