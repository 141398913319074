import React from "react";
import cover from "../../cover.png";
import axios from "axios";
import Constants from "../../common/Contants";
import { useHistory } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function IndiaMart(props) {
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const indiaMartMobile = event.target.indiaMartMobile.value;
    const indiaMartKey = event.target.indiaMartKey.value;

    console.log(props.location.state);

    const { emailId } = props.location.state;

    const userDetails = props.location.state;

    if (
      indiaMartMobile &&
      indiaMartMobile !== "" &&
      indiaMartKey &&
      indiaMartKey !== ""
    ) {
      const post = {
        emailId,
        indiaMartMobile,
        indiaMartKey,
      };

      axios
        .post(Constants.URL.indiaMartConfigureURL, post)
        .then(function (response) {
          console.log(response);
          userDetails.indiaMartMobile = indiaMartMobile;
          userDetails.indiaMartKey = indiaMartKey;
          history.push({
            pathname: "/message",
            state: {
              message:
                "Successfully Configured IndiaMart Details. IndiaMart API provides data once only in 15 minutes. Please access the application after some time.",
              type: "info"
            },
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Client Id, Client Secret and Authorization Code are mandatory");
    }
  };

  return (
    <div style={{ backgroundImage: `url(${cover})`, height: "100%" }}>
      <Header/>
      <div className="login-form-bg h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-xl-6">
              <div className="form-input-content">
                <div className="card login-form mb-0">
                  <div className="card-body pt-5">
                    <h3 className="card-title mt-3 text-center">
                      Configure India Mart
                    </h3>

                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="clientId">India Mart Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="indiaMartMobile"
                          name="indiaMartMobile"
                          aria-describedby="emailHelp"
                          placeholder="India Mart Mobile Number"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="clientId">India Mart Key</label>
                        <input
                          type="text"
                          className="form-control"
                          id="indiaMartKey"
                          name="indiaMartKey"
                          aria-describedby="emailHelp"
                          placeholder="Enter India Mart Key"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn login-form__btn submit w-100">
                          Configure
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default IndiaMart;
