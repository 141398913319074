import React from "react";
import { hash } from "../../common/HashUtility";
import Constants from "../../common/Contants";
import './register.scss';
import axios from "axios";
import { useHistory } from "react-router-dom";
import Slide11 from "../../cover.png";
import Loader from "../loader/Loader";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function Register(props) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const fullName = event.target.fullName.value;
    const email = event.target.email.value;
    const phone = event.target.phone.value;
    const password = event.target.password.value;
    const confirmPassword = event.target.confirmPassword.value;

    if (password === confirmPassword) {
      setLoading(true);
      const hashedPassword = hash(password);
      const post = {
        emailId: email,
        fullName: fullName,
        phone: phone,
        hashcode: hashedPassword,
      };

      axios
        .post(Constants.URL.createUserURL, post)
        .then(function (response) {
          console.log(response);
          setLoading(false)
          history.push("/login");
        })
        .catch(function (error) {
        });
    } else {
      alert("Passwords don't match");
    }
  };

  const handleLoginBtnClick = () => {
    history.push("/");
  };

  return (
    loading ? <Loader/> :
    <div style={{ backgroundImage: `url(${Slide11})`, height: "100%"}}>
      <Header/>
      <div className="login-form-bg h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-xl-5">
              <div className="form-input-content">
                <div className="card login-form mb-0">
                  <div className="card-body pt-5">
                    <h3 className="card-title mt-3 text-center">Register</h3>

                    <form onSubmit={handleSubmit}>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-user icon-align"></i>
                          </span>
                        </div>
                        <input
                          name="fullName"
                          className="form-control"
                          placeholder="Full name"
                          type="text"
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-envelope icon-align"></i>
                          </span>
                        </div>
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Email address"
                          type="email"
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-phone icon-align"></i>
                          </span>
                        </div>
                        <input
                          name="phone"
                          className="form-control"
                          placeholder="Phone number"
                          type="text"
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-lock icon-align"></i>
                          </span>
                        </div>
                        <input
                          name="password"
                          className="form-control"
                          placeholder="Create password"
                          type="password"
                        />
                      </div>
                      <div className="form-group input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text icon-container">
                            <i className="fa fa-lock icon-align"></i>
                          </span>
                        </div>
                        <input
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm password"
                          type="password"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn login-form__btn submit w-100">
                          Create Account
                        </button>
                      </div>
                      <p className="text-center">
                        Have an account?
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={handleLoginBtnClick}>
                          Log In
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Register;
