import React from "react";
import axios from "axios";
import Constants from "../../common/Contants";
import { useHistory } from "react-router-dom";
import MaterialTable from "../table/MaterialTable";
import Loader from "../loader/Loader";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./dashboard.css";
import moment from "moment";
import {
  Link
} from "react-router-dom";

function Dashboard(props) {
  const [leadsData, setLeadsData] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const [showLoader, setShowLoader] = React.useState(false);
  const [selectedLeads, setSelectedLeads] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment().subtract(7, "d").toDate()
  );
  const [endDate, setEndDate] = React.useState(moment().toDate());
  const history = useHistory();

  const transformLeadsData = (data) => {
    return data.map((lead) => {
      return {
        Company: lead.GLUSR_USR_COMPANYNAME && lead.GLUSR_USR_COMPANYNAME !== '' ? lead.GLUSR_USR_COMPANYNAME : 'NA',
        Email: lead.SENDEREMAIL,
        Description: lead.ENQ_MESSAGE,
        Full_Name: lead.SENDERNAME,
        Mobile: lead.MOB,
        Phone: lead.PHONE,
        City: lead.ENQ_CITY,
        Country: lead.COUNTRY_ISO,
        State: lead.ENQ_STATE,
        First_Name: lead.SENDERNAME ? lead.SENDERNAME.split(" ")[0] : "",
        Last_Name: lead.SENDERNAME
          ? lead.SENDERNAME.split(" ")[lead.SENDERNAME.split(" ").length - 1]
          : "",
        Secondary_Email: lead.EMAIL_ALT,
        Street: lead.ENQ_ADDRESS,
        IMC_PRODUCT: lead.PRODUCT_NAME,
        IMC_QUERY_ID: lead.QUERY_ID,
        IMC_QUERY_TYPE: lead.QTYPE,
        IMC_ENQUIRY_TIME: lead.DATE_TIME_RE,
        IMC_CALL_DURATION: lead.ENQ_CALL_DURATION,
        IMC_RECEIVED_MOBILE: lead.ENQ_RECEIVER_MOB,
        Lead_Source: "INDIAMART"
      };
    });
  };

  const getSelected = () => {
    if (selectedLeads.length === leadsData.length) {
      return leadsData;
    } else {
      const filterData = leadsData.filter((lead) =>
        selectedLeads.includes(lead.Full_Name)
      );
      console.log(filterData);
      return filterData;
    }
  };
  const addLeadsToZoho = async (type) => {
    const postData = type === "all" ? leadsData : getSelected();
    const { refreshToken, clientId, clientSecret, domain, assignmentRuleId } = props.location.state;
    if (postData.length === 0) {
      alert("Please select a row to add a Lead to ZOHO CRM.");
      return;
    }
    const data = {
      refreshToken,
      clientId,
      clientSecret,
      leadsData: postData,
      domain,
      assignmentRuleId
    };
    setShowLoader(true);
    axios
      .post(
        "https://w0lmjdcoi9.execute-api.ap-south-1.amazonaws.com/dev/addLeadsToZoho",
        data
      )
      .then((resp) => {
        setShowLoader(false);
        alert("Successfully Added leads to Zoho CRM");
      })
      .catch(function (error) {
        setShowLoader(false);
        alert("Error Adding Leads to Zoho CRM");
      });
  };

  React.useEffect(() => { }, [leadsData]);

  const getLeads = () => {
    if (props.location && !props.location.state) {
      history.push("/");
    } else {
      const { indiaMartMobile, indiaMartKey } = props.location.state;
      const url = `${Constants.URL.indiaMartDataURL
        }${indiaMartMobile}/${indiaMartKey}/${moment(startDate).format(
          "DD-MMM-YYYY"
        )}/${moment(endDate).format("DD-MMM-YYYY")}`;
      console.log(url);
      setDataLoaded(false);
      setShowLoader(true);
      axios
        .get(url)
        .then(async (resp) => {
          const transformedData = transformLeadsData(resp.data);
          await setLeadsData(transformedData);
          setDataLoaded(true);
          setShowLoader(false);
        })
        .catch(function (error) {
          setDataLoaded(false);
          setShowLoader(false);
          history.push({
            pathname: "/message",
            state: {
              message: "Failed to retrieve Leads from IndiaMart. IndiaMart API has a 15 minutes time difference between two consecutive hits. Please try again after sometime",
              type: "danger",
            },
          });
        });
    }
  };
  const onSelectLeads = (leads) => {
    setSelectedLeads(leads);
  };

  const onChangeStartDate = (event, value) => {
    console.log(event);
    console.log(value);
    setStartDate(event);
  };

  const onChangeEndDate = (event, value) => {
    console.log(event);
    console.log(value);
    setEndDate(event);
  };

  const onClickZohoConfigure = () => {
    const stateData = Object.assign({}, props.location.state);
    props.history.push({ pathname: "/zohoCRM", state: stateData });
  }

  return (
    <div className="" style={{ padding: "2rem" }}>
      <div className="topnav" id="myTopnav">
        <a href="" className=""><b>IndiaMART Leads</b></a>
        <div className="dropdown">
          <button className="dropbtn">Welcome {props.location.state ? props.location.state.fullName : "User"}
          </button>
          <div className="dropdown-content">
            <Link to={{ pathname: '/zohoCRM', state: props.location.state }}>Configure ZOHO CRM</Link>
          </div>
        </div>
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ margin: 0 }}>
        <KeyboardDatePicker
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginRight: "0.5rem",
          }}
          margin="normal"
          id="startDate"
          label="Start Date"
          format="dd-MMM-yyyy"
          value={startDate}
          onChange={(event, value) => {
            onChangeStartDate(event, value);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <KeyboardDatePicker
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginRight: "0.5rem",
          }}
          margin="normal"
          id="endDate"
          label="End Date"
          format="dd-MMM-yyyy"
          value={endDate}
          onChange={(event, value) => {
            onChangeEndDate(event, value);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />

        <button
          className="btn btn-primary"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
          }}
          onClick={() => getLeads()}>
          Get Leads
        </button>
        <button
          className="btn btn-primary"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
          }}
          onClick={() => addLeadsToZoho("selected")}>
          Add Selected Leads
        </button>
      </MuiPickersUtilsProvider>

      {dataLoaded && (
        <MaterialTable rows={leadsData} onSelectLeads={onSelectLeads} />
      )}

      {showLoader && <Loader />}
    </div>
  );
}

export default Dashboard;
