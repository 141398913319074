const Constants = {
  URL: {
    createUserURL:
      "https://w0lmjdcoi9.execute-api.ap-south-1.amazonaws.com/dev/user",
    fetchUserURL:
      "https://w0lmjdcoi9.execute-api.ap-south-1.amazonaws.com/dev/user",
    zohoCRMConfigureURL:
      "https://w0lmjdcoi9.execute-api.ap-south-1.amazonaws.com/dev/updateUserForZohoCRM",
    indiaMartConfigureURL:
      "https://w0lmjdcoi9.execute-api.ap-south-1.amazonaws.com/dev/updateUserForIndiaMart",
    indiaMartDataURL:
      "https://w0lmjdcoi9.execute-api.ap-south-1.amazonaws.com/dev/getIndiaMartData/",
  },
  TABLE_CONFIG: {
    leadsTableColumns: [
      "Full Name",
      "Product Interested",
      "Description",
      "First Name",
      "Last Name",
      "Company",
      "Email", 
      "Secondary Email",
      "Mobile",
      "Phone",
      "Street",
      "City",
      "State",
      "Country" 
    ]
  },
};

export default Constants;
