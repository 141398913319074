import React from "react";

function Message(props) {
  let message,
    type = "";
  if (props.location.state) {
    message = props.location.state.message;
    type = props.location.state.type;
  }
  const renderMessage = (msgType) => {
    switch (msgType) {
      case "success":
        return (
          <div class="alert alert-success">
            <strong>SUCCESS : </strong> {message}
          </div>
        );
      case "warning":
        return (
          <div class="alert alert-warning">
            <strong>WARNING : </strong> {message}
          </div>
        );

      case "info":
        return (
          <div class="alert alert-info">
            <strong>INFO : </strong> {message}
          </div>
        );

      case "danger":
        return (
          <div class="alert alert-danger">
            <strong>ERROR : </strong> {message}
          </div>
        );
      default:
        return (
          <div class="alert alert-info">
            <strong>INFO : </strong> Unable to display Message
          </div>
        );
    }
  };

  return <div style={{textAlign:"center",marginTop:"20%"}}>{renderMessage(type)}</div>;
}

export default Message;
