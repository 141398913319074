import React, { useEffect } from "react";
import cover from "../../cover.png";
import axios from "axios";
import Constants from "../../common/Contants";
import { useHistory } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import userEvent from "@testing-library/user-event";

function Zoho(props) {

  const handleSubmit = (event) => {
    event.preventDefault();
    const clientId = event.target.clientId.value;
    const clientSecret = event.target.clientSecret.value;
    const authCode = event.target.authCode.value;
    const assignmentRuleId = event.target.assignmentRuleId.value;

    const { emailId, indiaMartMobile, indiaMartKey } = props.location.state;

    if (
      clientId &&
      clientId !== "" &&
      clientSecret &&
      clientSecret !== "" &&
      authCode &&
      authCode !== "" &&
      assignmentRuleId &&
      assignmentRuleId !== ""
    ) {
      const post = {
        emailId,
        clientId,
        clientSecret,
        authCode,
        redirect_uri: "www.dummyurl.com",
        assignmentRuleId
      };

      axios
        .post(Constants.URL.zohoCRMConfigureURL, post)
        .then(function (response) {
          console.log(response);
          if (!indiaMartMobile || !indiaMartKey) {
            props.history.push({ pathname: "/indiaMart", state: props.location.state });
          } else {
            props.history.push({ pathname: "/dashboard", state: props.location.state });
          }
        })
        .catch(function (error, response) {
          console.log(error.response);
          alert(
            error.response.data.error
          );
        });
    } else {
      alert("Client Id, Client Secret and Authorization Code, Authorization Rule Assignment are mandatory");
    }
  };


  return (
    <div style={{ backgroundImage: `url(${cover})`, height: "100%" }}>
      <Header />
      <div className="login-form-bg h-100" style={{ marginTop: '5.2rem' }}>
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-xl-6">
              <div className="form-input-content">
                <div className="card login-form mb-0">
                  <div className="card-body pt-5">
                    <h3 className="card-title mt-3 text-center">
                      Configure Zoho CRM
                    </h3>

                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="clientId">Client ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="clientId"
                          name="clientId"
                          aria-describedby="emailHelp"
                          placeholder="Enter Client ID"
                          defaultValue={props.location.state && props.location.state.clientId ? props.location.state.clientId : ""}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="clientSecret">Client Secret</label>
                        <input
                          type="text"
                          className="form-control"
                          id="clientSecret"
                          name="clientSecret"
                          placeholder="Client Secret"
                          defaultValue={props.location.state && props.location.state.clientSecret ? props.location.state.clientSecret : ""}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="authCode">Authorization Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="authCode"
                          name="authCode"
                          placeholder="Enter Authorization Code"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="authCode">Assignment Rule ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="assignmentRuleId"
                          name="assignmentRuleId"
                          placeholder="Enter Assignment Rule ID"
                        />
                      </div>


                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn login-form__btn submit w-100">
                          Configure
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Zoho;
