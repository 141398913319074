import React from "react";

function Footer(props) {
  return (
    <div
      className="footer"
      style={{
        paddingLeft: "0",
        position: "fixed",
        width: "100%",
        bottom: "0",
        borderTop: "2px solid #7571f9",
        zIndex: "10000",
      }}>
      <div className="copyright">
        <p>© Excelict Technology Consulting Pvt. Ltd.</p>
      </div>
    </div>
  );
}

export default Footer;
