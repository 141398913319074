import React from "react";
import "./App.css";
import "../assets/css/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../Routes";

function App(props) {
  return (
    <div className="main-wrapper" style={{ height: "100vh" }}>
      <div className="content-body body-alignment">
        <Router>
          <Routes />
        </Router>
      </div>
    </div>
  );
}

export default App;
