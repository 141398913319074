import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./component/login/Login";
import Register from "./component/register/Register";
import NavHeader from "./component/navheader/NavHeader";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import Zoho from "./component/zoho/Zoho";
import IndiaMart from "./component/indiamart/IndiaMart";
import Dashboard from "./component/dashboard/dashboard";
import Message from "./component/message/Message";
import MaterialTable from "./component/table/MaterialTable";
import data from "./component/dashboard/data";

export default function Routes() {
  return (
    <div style={{ height: "100%" }}>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/navHeader" component={NavHeader} />
        <Route path="/header" component={Header} />
        <Route path="/footer" component={Footer} />
        <Route path="/zohoCRM" component={Zoho} />
        <Route path="/indiaMart" component={IndiaMart} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/message" component={Message} />
        <Route path="/table" component={() => <MaterialTable rows={data} />} />
      </Switch>
    </div>
  );
}
